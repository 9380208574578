import fetch from "@adobe/node-fetch-retry"
import { useCallback, useEffect, useRef, useState } from "react"

import { isValidEmail } from "@utils/validators"

export enum FormError {
  Generic,
  InvalidEmail,
  UncheckedTC,
}

export const useContactForm = (page: string) => {
  const refEmail = useRef<HTMLInputElement>(null)
  const refTC = useRef<HTMLInputElement>(null)
  const [token, setToken] = useState<string | null>(null)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<FormError | null>(null)
  const interacted = useRef(false)

  // submit newsletter form handler
  const submit = useCallback(async () => {
    // check existence of turnstile token
    if (!token) {
      console.error("could not submit newsletter form: cloudflare turnstile token not found")
      return
    }

    // do nothing if request still ongoing
    if (loading) return

    const email = refEmail.current!.value
    const checkedTC = refTC.current?.checked

    if (!isValidEmail(email)) {
      setError(FormError.InvalidEmail)
      return
    }

    if (refTC.current !== null && !checkedTC) {
      setError(FormError.UncheckedTC)
      return
    }

    try {
      // reset error and loading state
      setLoading(true)
      setError(null)

      const response = await fetch("/api/submit-contact-form/", {
        method: "POST",
        body: JSON.stringify({ page, email, token }),
      })
      if (response.ok) {
        setHasSubmitted(true)

        // form success submission tracking
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ event: "formSubmit", name: `Kontakta oss ${page}` })
      } else throw new Error(response.statusText)

      // reset input element's value
      refEmail.current!.value = ""
      refEmail.current!.blur()
      refTC.current!.checked = false
    } catch (e) {
      console.error(e)
      setError(FormError.Generic)
    } finally {
      setLoading(false)
    }
  }, [token, loading, page])

  // bind to enter key for form submission
  useEffect(() => {
    if (refEmail.current) {
      const inputRefEmail = refEmail.current
      const handler = (e: KeyboardEvent) => {
        if (e.code === "Enter") submit()
      }

      const focusHandler = () => {
        if (!interacted.current) {
          // form interaction tracking
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({ event: "formInteraction", name: `Kontakta oss ${page}` })

          interacted.current = true
        }
      }

      inputRefEmail.addEventListener("keyup", handler)
      inputRefEmail.addEventListener("focus", focusHandler)

      return () => {
        inputRefEmail.removeEventListener("keyup", handler)
        inputRefEmail.removeEventListener("focus", focusHandler)
      }
    }
  }, [page, submit])

  return { submit, hasSubmitted, loading, error, refEmail, refTC, setToken }
}

export const useNewsletterForm = (formName: string) => {
  const refEmail = useRef<HTMLInputElement>(null)
  const refTC = useRef<HTMLInputElement>(null)
  const [token, setToken] = useState<string | null>(null)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<FormError | null>(null)
  const interacted = useRef(false)

  // submit newsletter form handler
  const submit = useCallback(async () => {
    // check existence of turnstile token
    if (!token) {
      console.error("could not submit newsletter form: cloudflare turnstile token not found")
      return
    }

    // do nothing if request still ongoing
    if (loading) return

    const email = refEmail.current!.value
    const checkedTC = refTC.current!.checked

    if (!isValidEmail(email)) {
      setError(FormError.InvalidEmail)
      return
    }

    if (refTC.current !== null && !checkedTC) {
      setError(FormError.UncheckedTC)
      return
    }

    try {
      // reset error and loading state
      setLoading(true)
      setError(null)

      const response = await fetch("/api/submit-newsletter-form/", {
        method: "POST",
        body: JSON.stringify({ email, token }),
      })
      if (response.ok) {
        setHasSubmitted(true)

        // form success submission tracking
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ event: "formSubmit", name: formName })
      } else throw new Error(response.statusText)

      // reset input element's value
      refEmail.current!.value = ""
      refEmail.current!.blur()
    } catch (e) {
      console.error(e)
      setError(FormError.Generic)
    } finally {
      setLoading(false)
    }
  }, [token, formName, loading])

  // bind to enter key for form submission
  useEffect(() => {
    if (refEmail.current) {
      const inputRefEmail = refEmail.current
      const handler = (e: KeyboardEvent) => {
        if (e.code === "Enter") submit()
      }

      const focusHandler = () => {
        if (!interacted.current) {
          // form interaction tracking
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({ event: "formInteraction", name: formName })

          interacted.current = true
        }
      }

      inputRefEmail.addEventListener("keyup", handler)
      inputRefEmail.addEventListener("focus", focusHandler)

      return () => {
        inputRefEmail.removeEventListener("keyup", handler)
        inputRefEmail.removeEventListener("focus", focusHandler)
      }
    }
  }, [formName, submit])

  return { submit, hasSubmitted, loading, error, refEmail, refTC, setToken }
}
