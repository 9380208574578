import { useEffect, useMemo } from "react"

export function useTurnstile({ id, setToken }: { id: string; setToken: (token: string) => void }) {
  // we need to initialize turnstile whenever the component is mounted
  // and this should only be done once, setToken is not a dependency
  useEffect(() => {
    if (typeof window !== "undefined" && window.turnstile) {
      window.turnstile.render(`#cf-turnstile-${id}`, {
        sitekey: process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY!,
        callback: (token: string) => setToken(token),
      })
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  // return the attributes for the turnstile div
  return useMemo(
    () => ({
      id: `cf-turnstile-${id}`,
      "data-action": id,
      "data-sitekey": process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY!,
      "data-refresh-expired": "auto",
    }),
    [id]
  )
}
