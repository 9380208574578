import type { AppProps } from "next/app"

import "/styles/globals.css"
import localFont from "next/font/local"
import { Analytics } from "@vercel/analytics/react"
import { withPasswordProtect } from "next-password-protect"
import { useEffect } from "react"

import { ProgressBar } from "@components/shared"
import { CookieBanner } from "@components/widgets/cookie-banner"

const fontMatter = localFont({
  src: [
    { path: "../assets/fonts/Matter-Regular.woff2", weight: "400", style: "normal" },
    { path: "../assets/fonts/Matter-RegularItalic.woff2", weight: "400", style: "italic" },
  ],
  variable: "--matter",
})

const fontRecklessNeue = localFont({
  src: [
    { path: "../assets/fonts/RecklessNeue-Book.woff2", weight: "400", style: "normal" },
    { path: "../assets/fonts/RecklessNeue-BookItalic.woff2", weight: "400", style: "italic" },
  ],
  variable: "--reckless-neue",
})

const CederquistApp = ({ Component, pageProps }: AppProps) => {
  // inject css variables for fonts in the body tag
  useEffect(() => document.body.classList.add(fontMatter.variable, fontRecklessNeue.variable), [])

  return (
    <>
      <ProgressBar />
      <Component {...pageProps} />
      <Analytics />

      {typeof document !== "undefined" &&
        document.querySelector("#CookieBot") === null &&
        process.env.NEXT_PUBLIC_APP_ENV &&
        process.env.NEXT_PUBLIC_APP_ENV !== "development" && <CookieBanner />}
    </>
  )
}

export default process.env.PASSWORD_PROTECT
  ? withPasswordProtect(CederquistApp, {
      loginApiUrl: "/api/beta-login/",
      checkApiUrl: "/api/beta-password-check/",
    })
  : CederquistApp
